/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

window.msnryOptions = {
    columnWidth: 300,
    gutter: 20
};

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                /*
                $('.menu-header li').each(function(i, d) {
                    $(d).on({
                        mouseenter: function() {
                            $(d).find('.sub-menu').addClass('active');
                        },
                        mouseleave: function() {
                            setTimeout(function() {
                                $(document).mouseover(function() {
                                    if( $(this) != $(d) ) {
                                        $(d).find('.sub-menu').removeClass('active');
                                    }
                                });
                            }, 400);
                        },
                    });
                });
                */

                /*
                var setMsnryOptions = function() {
                    if( $(window).width() > 1200 ) {
                        window.msnryOptions.columnWidth = 360;
                        window.msnryOptions.gutter = 30;
                    } else if( $(window).width() < 1200 && $(window).width() > 992 ) {
                        window.msnryOptions.columnWidth = 300;
                        window.msnryOptions.gutter = 20;
                    } else if( $(window).width() < 992 && $(window).width() > 768 ) {
                        window.msnryOptions.columnWidth = 350;
                        window.msnryOptions.gutter = 20;
                    } else if( $(window).width() < 768 ) {
                        window.msnryOptions.columnWidth = '.partial-post';
                        window.msnryOptions.gutter = 0;
                    }
                };


                setMsnryOptions();

                $(window).resize(function() {
                    setMsnryOptions();
                });
                */


                var setMobileMenu = function() {
                    if( $(window).width() < 768 ) {
                        $('.header-nav-hamburger').on('click tap', function(e) {
                            if( $('.header-nav-hamburger').hasClass('opened') === true ) {
                                $('.header-nav-hamburger').removeClass('opened');
                                $('.header-nav-container').slideUp();
                            } else {
                                $('.header-nav-hamburger').addClass('opened');
                                $('.header-nav-container').slideDown();
                            }
                        });

                        $('.header-nav .sub-menu').each(function(i, d) {
                            $(d).slideUp();
                        });

                        $('.header-nav > li.menu-item-has-children').on('click tap', function(e) {
                            e.preventDefault();
                            var $submenu = $(this).find('.sub-menu');

                            if( $submenu.hasClass('active') === true ) {
                                // redirect to link
                                window.location = $(this).find('a').attr('href');
                                //$(this).find('sub-menu').removeClass('active');
                            } else {
                                // close all menus
                                $('.header-nav .sub-menu').removeClass('active').slideUp();
                                // open menu
                                $(this).find('.sub-menu').addClass('active').slideDown();
                            }
                        });
                    }
                };

                setMobileMenu();

                $(window).resize(function() {
                    setMobileMenu();
                });

                $('.partial-post').css('pointer', 'cursor').on('click tap', function(e) {
                    e.preventDefault();
                    window.location = $(this).attr('data-href');
                });

                var partialPostMaxHeight = 0;
                $('.partial-post').each(function(i, d) {
                    if( $(d).outerHeight() > partialPostMaxHeight ) {
                        partialPostMaxHeight = $(d).outerHeight();
                    }
                }).promise().done(function() {
                    $('.partial-post').css('height', partialPostMaxHeight);
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Blog
        'blog': {
            init: function() {
                /*
                $('.page-content-posts-container').masonry({
                    itemSelector: '.partial-post',
                    columnWidth: window.msnryOptions.columnWidth,
                    gutter: window.msnryOptions.gutter
                });
                */
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Archive
        'archive': {
            init: function() {
                /*
                $('.page-content-posts-container').masonry({
                    itemSelector: '.partial-post',
                    columnWidth: window.msnryOptions.columnWidth,
                    gutter: window.msnryOptions.gutter
                });
                */

                /*
                $('.page-content-posts-container').css({
                    width: $('.page-content-posts-container').parent().width() + 'px !important'
                });
                */

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Homepage
        'page_template_template_homepage': {
            init: function() {
                $('.homepage-bloc-container').on('click tap', function(e) {
                    e.preventDefault();
                    window.location = $(this).attr('data-href');
                });

                /*
                $('.homepage-bloc-title').on('click tap', function(e) {
                    e.preventDefault();
                    window.location = $(this).parent().attr('data-href');
                });
                */

                /*
                $('.homepage-featured-articles-container').masonry({
                    itemSelector: '.partial-post',
                    columnWidth: window.msnryOptions.columnWidth,
                    gutter: window.msnryOptions.gutter
                });

                $('.homepage-articles').masonry({
                    itemSelector: '.partial-post',
                    columnWidth: window.msnryOptions.columnWidth,
                    gutter: window.msnryOptions.gutter
                });
                */
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Contact
        'page_template_template_contact': {
            init: function() {
                var $form = $('.contact-form-file-upload');
                var $p = $form.find('.ginput_container_fileupload');

                var handleBrowseClick = function($originalFileInput) {
                    //var fileinput = document.getElementById("browse");
                    $originalFileInput.trigger('click');
                    //fileinput.click();
                };

                var handleChange = function(filename) {
                    var name = filename.split('/').slice(-1)[0];
                    name = name.split('\\').slice(-1)[0];
                    $('.contact-form-fake-filename').val(name);
                };

                var createUploadBtn = function($container, uploadBtnText) {
                    var $originalFileInput = $container.find('#input_1_6');
                    var $filenameInput = $('<input type="text" class="contact-form-fake-filename" readonly="true"/>');
                    var $uploadBtn = $('<input type="button" value="Télécharger une pièce jointe" class="contact-form-fake-upload-btn"/>');

                    $filenameInput.on('click tap', function(e) {
                        e.preventDefault();
                        handleBrowseClick($originalFileInput);
                    });

                    $uploadBtn.on('click tap', function(e) {
                        e.preventDefault();
                        handleBrowseClick($originalFileInput);
                    });

                    $originalFileInput.on('change', function(e) {
                        handleChange($originalFileInput.val());
                    });

                    $container.append($uploadBtn);
                    $container.append($filenameInput);
                };

                createUploadBtn($p, $p.find('.gfield_label').text());
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
